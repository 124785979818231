<template>
    <div class="online-judging full-height full-width" :class="{ mobile: $vuetify.breakpoint.xsOnly }">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <template v-if="myCompetitions && myCompetitions.length">
                <v-container fluid class="pa-6 overflow-y-scroll full-height full-width p-relative">
                    <v-row>
                        <v-col class="section-header d-flex align-start justify-space-between" cols="12">
                            <div class="d-flex align-center">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex align-center mr-6" v-bind="attrs" v-on="on">
                                                <h3
                                                    v-html="active && active.title ? active.title : 'All Competitions'"
                                                ></h3>
                                                <v-icon class="ml-2" color="dark">mdi-chevron-down</v-icon>
                                            </div>

                                            <template v-if="active && active._id">
                                                <template v-if="$status.competitionJudging(active)">
                                                    <div class="mt-3">
                                                        <span class="small--text"
                                                            >Judging finishes on
                                                            {{ $status.judgingEndDate(active) }}</span
                                                        >
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </template>

                                    <v-list class="list">
                                        <v-list-item
                                            color="primary"
                                            class="navigation-inactive"
                                            active-class="navigation-active"
                                            v-for="(competition, index) in competitionMenu"
                                            :key="index"
                                            @click="filterCompetitions(competition)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>{{ competition.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <div class="action-buttons">
                                <v-btn
                                    :key="cache"
                                    class="ml-3 light--text"
                                    color="dark"
                                    small
                                    :loading="active.finishing"
                                    :disabled="!allReady(active)"
                                    @click="openBatchSubmitDialog(active)"
                                    v-if="!allDone(active)"
                                    >Submit Scores</v-btn
                                >
                                <v-btn
                                    class="ml-3"
                                    color="primary"
                                    small
                                    @click="$refs.imageDialog.open('630564b90cbc05333860435b')"
                                    >View Calibration Image</v-btn
                                >
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <v-row v-for="competition in myCompetitions" :key="competition._id">
                                <v-col cols="12">
                                    <hr />
                                </v-col>

                                <v-col
                                    class="section-header d-flex align-start justify-space-between"
                                    cols="12"
                                    v-if="!active || !active._id"
                                >
                                    <div class="d-flex flex-column mr-6">
                                        <h3 class="mb-3">{{ competition.title }}</h3>

                                        <template v-if="$status.competitionJudging(competition)">
                                            <span class="small--text"
                                                >Judging finishes on {{ $status.judgingEndDate(competition) }}</span
                                            >
                                        </template>
                                    </div>

                                    <div class="action-buttons">
                                        <v-btn
                                            :key="cache"
                                            class="ml-3 light--text"
                                            color="dark"
                                            small
                                            :loading="competition.finishing"
                                            :disabled="!allReady(competition)"
                                            @click="openBatchSubmitDialog(competition)"
                                            v-if="!allDone(competition)"
                                            >Submit Scores</v-btn
                                        >
                                    </div>
                                </v-col>

                                <template v-if="competition.categories && competition.categories.length">
                                    <v-col cols="12" v-for="category in competition.categories" :key="category._id">
                                        <div class="d-flex flex-column mr-6">
                                            <h3 class="mb-3">{{ category.title }}</h3>
                                        </div>

                                        <template v-if="category.entries && category.entries.length">
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                    lg="2"
                                                    v-for="entry in category.entries"
                                                    :key="entry._id"
                                                >
                                                    <entry-tile
                                                        :entry="entry"
                                                        :competition="competition"
                                                        view="judge"
                                                        @click="open(entry)"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </template>

                                        <template v-else>
                                            <v-container fluid class="pa-3 full-height full-width">
                                                <div class="d-flex flex-column align-center justify-center full-height">
                                                    <p class="text-center mb-0">
                                                        There are no entries that need judging at the moment.
                                                    </p>
                                                </div>
                                            </v-container>
                                        </template>
                                    </v-col>
                                </template>

                                <template v-else>
                                    <v-container fluid class="pa-3 full-height full-width">
                                        <div class="d-flex flex-column align-center justify-center full-height">
                                            <p class="text-center mb-0">
                                                There are no entries that need judging at the moment.
                                            </p>
                                        </div>
                                    </v-container>
                                </template>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <template v-else>
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="d-flex flex-column align-center justify-center full-height">
                        <p class="text-center mb-0">There are no entries that need judging at the moment.</p>
                    </div>
                </v-container>
            </template>
        </template>

        <image-dialog ref="imageDialog"></image-dialog>
        <alert-dialog ref="finishDialog" title="Submit Scores" @confirm="confirmFinish"></alert-dialog>
    </div>
</template>

<script>
// Services
import Entry from "@/modules/app-module/entry";
import Competition from "@/modules/app-module/competition";
import Category from "@/modules/app-module/category";
import Score from "@/modules/app-module/score";

// Components
import EntryTile from "@/components/global/entry-tile.vue";
import ImageDialog from "@/components/dialogs/image-dialog.vue";
import AppTable from "@/components/table/app-table.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";

// Packages
import _ from "lodash";
import moment from "moment";

export default {
    name: "online-judging",

    meta: {
        title: "Online Judging",
    },

    components: {
        EntryTile,
        AppTable,
        AlertDialog,
        ImageDialog,
    },

    data() {
        return {
            competitions: [],
            entries: [],
            categories: [],
            form: {},
            loading: false,
            active: null,
            cache: 0,
        };
    },

    computed: {
        myCompetitions() {
            let user = this.$app.user;

            let competitions = this.competitions;
            let categories = this.categories;
            let entries = this.entries;

            competitions = competitions
                .map((competition) => {
                    let { _id, title, data } = competition;

                    let categoryJudges = competition?.data?.categoryJudges || [];
                    let feedbackJudges = competition?.data?.feedbackJudges || [];
                    //Determines the amount of feedbacks.scores before this entry is closed
                    let maxFeedbackCount = competition?.data?.maxFeedbackCount || null;
                    let maxScoreCount = competition?.data?.maxScoreCount || null;
                    console.log("maxes", maxFeedbackCount, maxScoreCount);
                    if (user) {
                        return {
                            _id,
                            title,
                            data,
                            categories: categories
                                .map((category) => {
                                    let { _id, title } = category;

                                    return {
                                        _id,
                                        title,
                                        entries: entries
                                            .map((entry) => {
                                                let { _id, title, data, managedOwners } = entry;

                                                let obj = {
                                                    _id,
                                                    title,
                                                    data,
                                                    managedOwners,
                                                };

                                                let posts = entry.posts;

                                                if (posts) {
                                                    let score = posts?.score;
                                                    let abstain = posts?.abstain;
                                                    let feedback = posts?.feedback;
                                                    if (score) {
                                                        let scorePost = score.posts.find(
                                                            (post) => post.managedAuthor == user.persona
                                                        );
                                                        // console.log("SCORE", scorePost);
                                                        obj.score = scorePost?.data?.score ? scorePost : null;
                                                    }
                                                    //lock entry from judging
                                                    obj.maxScore = score?.count >= maxScoreCount ? true : false;
                                                    obj.maxFeedback =
                                                        feedback?.count >= maxFeedbackCount ? true : false;
                                                    if (abstain) {
                                                        let abstainPost = abstain.posts.find(
                                                            (post) => post.managedAuthor == user.persona
                                                        );
                                                        // console.log("ABSTAIN", abstainPost);
                                                        obj.abstain = abstainPost ? true : false;
                                                    }

                                                    if (feedback) {
                                                        let feedbackPost = feedback.posts.find(
                                                            (post) => post.managedAuthor == user.persona
                                                        );
                                                        // console.log("FEEDBACK", feedbackPost);
                                                        obj.feedback = feedbackPost ? true : false;
                                                    }
                                                }

                                                // User is a judge in the competition/category
                                                let isCategoryJudge = categoryJudges?.some(
                                                    (item) =>
                                                        item?.category?._id == category?._id &&
                                                        item?.judges?.some((judge) => judge?._id == user.persona)
                                                );
                                                obj.isCategoryJudge = isCategoryJudge ? true : false;

                                                // User is a judge in the competition/feedback
                                                let isFeedbackJudge = feedbackJudges?.some(
                                                    (item) =>
                                                        item?.category?._id == category?._id &&
                                                        item?.judges?.some((judge) => judge?._id == user.persona)
                                                );
                                                obj.isFeedbackJudge = isFeedbackJudge ? true : false;

                                                return obj;
                                            })
                                            .filter((entry) => {
                                                // // User is a judge in the competition/category
                                                let isCategoryJudge = entry.isCategoryJudge;

                                                // // User is a judge in the competition/feedback and the user has requested feedback
                                                let isFeedbackJudge = entry.isFeedbackJudge && entry.data.feedback;

                                                // Entry is in competition
                                                let compMatch = competition?._id == entry?.data?.competition?._id;

                                                // Entry is in category
                                                let catMatch = category?._id == entry?.data?.category?._id;

                                                // Entries are not the users
                                                let notCurrentUser = !entry.managedOwners?.some(
                                                    (owner) => owner._id == user.persona
                                                );

                                                // Entry has been marked as submitted
                                                let submitted = entry?.data?.submitted;

                                                // Entry has enough information even though it wasn't submitted

                                                let hasEnoughInfo = this.$status.hasEnoughInfo(entry);

                                                return (
                                                    (isCategoryJudge || isFeedbackJudge) &&
                                                    compMatch &&
                                                    catMatch &&
                                                    notCurrentUser &&
                                                    (hasEnoughInfo || submitted)
                                                );
                                            })
                                            .sort((a, b) => {
                                                let aScore = Number(a?.score?.data?.score || 0);
                                                let bScore = Number(b?.score?.data?.score || 0);

                                                return bScore - aScore;
                                            }),
                                    };
                                })
                                .filter((category) => category?.entries?.length),
                        };
                    }
                })
                .filter((competition) => {
                    // // Has some categories
                    let hasCategories = competition?.categories?.length;

                    // Is being filtered by the menu
                    let isSelected = true;
                    if (this?.active?._id) {
                        isSelected = competition._id == this.active._id;
                    }

                    let isOnline = competition?.data?.judgingType == "online";

                    let judging = competition?.data?.judging;
                    let judgingClosed = judging.closed;
                    let judgingStartDate = moment(judging.startDate).format("YYYY-MM-DD");
                    let judgingEndDate = moment(judging.endDate).format("YYYY-MM-DD");
                    let currentDate = moment().format("YYYY-MM-DD");

                    // Current date is in between judging start and end dates. It is also not marked as "closed"
                    let judgingOpen = currentDate >= judgingStartDate && currentDate < judgingEndDate && !judgingClosed;

                    return isSelected && isOnline && judgingOpen;
                });

            return competitions.reverse(); //To change the order in which they are displaed, newest first
        },
        competitionMenu() {
            let competitions = _.cloneDeep(this.myCompetitions);

            competitions.unshift({ title: "All Competitions" });

            return competitions;
        },
    },

    methods: {
        filterCompetitions(competition) {
            this.active = competition || null;
        },
        async init() {
            this.loading = true;

            try {
                await this.$fluro.resetCache();

                //Changed to custom query to improve loadtime

                // const entriesPerPage = 1000; // Number of entries per page
                // let pageNumber = 1; // Initial page number
                // let allEntries = []; // Array to store all entries
                // let fetching = true;

                // while (fetching) {
                //     const response = await fetch(
                //         "https://hl66letcw8.execute-api.ap-southeast-2.amazonaws.com/dev/onlineJudgeFilter",
                //         {
                //             method: "POST",
                //             headers: {
                //                 "Content-Type": "application/json",
                //                 Authorization: this.$fluro.auth.getCurrentToken(),
                //             },
                //             body: JSON.stringify({
                //                 realm: this.$app.liveRealm,
                //                 competitions: this.competitions,
                //                 categories: this.categories,
                //                 pageNumber: pageNumber,
                //                 pageSize: entriesPerPage,
                //             }),
                //         }
                //     );

                //     const data = await response.json();

                //     if (response.ok) {
                //         const entries = data.entries;
                //         allEntries = allEntries.concat(entries);

                //         // If the number of entries received is less than the requested page size,
                //         // it means all entries have been retrieved, so exit the loop
                //         if (entries.length < entriesPerPage) {
                //             fetching = false;
                //             break;
                //         }

                //         pageNumber++;
                //     } else {
                //         // Handle the error
                //         console.error(data.error);
                //         fetching = false;
                //         break;
                //     }
                // }

                // console.log("ALL ENTREIES", allEntries);
                // this.entries = allEntries;

                const entryQuery = {
                    status: "active",
                    definition: "entry",
                    realms: {
                        $in: this.$app.liveRealm,
                    },
                };

                const competitionQuery = {
                    status: "active",
                    definition: "competition",
                    "data.judgingType": "online",
                    realms: {
                        $in: this.$app.liveRealm,
                    },
                };

                const categoryQuery = {
                    status: "active",
                    definition: "category",
                    realms: {
                        $in: this.$app.liveRealm,
                    },
                }; // Add your specific query criteria for categories

                const entryQueries = [];

                // Generate queries for the previous 12 months
                for (let i = 0; i < 12; i++) {
                    const startDate = new Date();
                    startDate.setUTCMonth(startDate.getUTCMonth() - i, 1);
                    startDate.setUTCHours(0, 0, 0, 0);

                    const endDate = new Date(startDate);
                    endDate.setUTCMonth(endDate.getUTCMonth() + 1);

                    const entryQuery = {
                        status: "active",
                        definition: "entry",
                        realms: {
                            $in: this.$app.liveRealm,
                        },
                        created: {
                            $gte: startDate,
                            $lt: endDate,
                        },
                    };

                    entryQueries.push(entryQuery);
                }

                const entryPromises = entryQueries.map((query) =>
                    Entry.query(query, { params: { status: "active", appendPosts: "all" } })
                );
                const entryResponses = await Promise.all(entryPromises);
                this.entries = entryResponses.flatMap((response) => response.data);

                const [competitionResult, categoryResult] = await Promise.all([
                    Competition.query(competitionQuery).then(({ data }) => data),
                    Category.query(categoryQuery).then(({ data }) => data),
                ]);

                this.competitions = competitionResult;
                this.categories = categoryResult;

                // this.form = await this.$fluro.types.get("entry").then((res) => res.definition);

                console.log("ENTRIES", this.entries);
                console.log("COMPETITIONS", this.competitions);
                console.log("CATEGORIES", this.categories);
                // console.log("FORM", this.form);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;

            this.cache++;
        },
        allReady(competition) {
            let entries = competition.categories.map(({ entries }) => entries).flat();

            return entries
                .filter((entry) => entry?.data?.competition?._id == competition?._id)
                .every((entry) => entry?.score?._id || entry.abstain);
        },
        allDone(competition) {
            // Need this if the user is filtering by a comp and they just did a batch submit
            // The "competition" parameter isn't the latest version of itself. ie. It doesn't have the updated posts marked as "submitted"
            competition = this.myCompetitions.find((comp) => comp?._id == competition?._id);

            // It's including the "All Competitions"
            let entries = competition ? competition.categories.map(({ entries }) => entries).flat() : [];

            return entries
                .filter((entry) => entry?.data?.competition?._id == competition?._id)
                .every((entry) => (entry?.score?._id && entry?.score?.data?.submitted) || entry.abstain);
        },
        open(item) {
            this.$router.push({ name: "online-judging-submission", params: { id: item._id } });
        },
        openBatchSubmitDialog(competition) {
            let entries = competition.categories.map(({ entries }) => entries).flat();
            entries = entries.filter(
                (entry) =>
                    entry?.data?.competition?._id == competition._id &&
                    entry?.score?.data?.score &&
                    !entry?.score?.data?.submitted
            );

            let model = {
                competition,
                entries,
            };

            if (entries.length) {
                this.$refs.finishDialog.open(
                    model,
                    `You currently have <b>${entries.length} entries</b> that have been scored but not submitted. Are you sure you want to submit <b>${entries.length} entries</b> in <b>${competition.title}</b>? This will finalise your scores and you will not be able to edit them again.`
                );
            }
        },
        async confirmFinish(model) {
            model.competition.finishing = true;

            try {
                let loop = async () => {
                    let entries = model.entries;

                    for (var i = 0; i < entries.length; i++) {
                        let entry = entries[i];

                        try {
                            let score = entry.score;

                            let payload = {
                                data: {
                                    submitted: true,
                                },
                            };

                            let updated = await Score.update(score._id, payload).then(({ data }) => data);
                            console.log("UPDATED", updated);
                        } catch (err) {
                            console.log("ERROR", err);
                        }
                    }
                };

                await loop();

                await this.init();

                this.$refs.finishDialog.close();
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss">
.online-judging {
    &.mobile {
        .section-header {
            flex-direction: column;
        }

        .action-buttons {
            display: flex;
            flex-direction: column;
            width: 100%;

            .v-btn {
                margin: 15px 0 0 0 !important;
                width: 100%;
            }
        }
    }
}
</style>
